
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const content = {
  "title": "Build detailed scenarios",
  "link": {
    "title": "Learn more",
    "href": "/learn#detail"
  }
};

const layoutProps = {
  content
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Create multi-modal networks automatically from open data sources, and sketch new scenarios with the details that matter for people walking, cycling, and riding transit. 🚶🚲 🚌`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;