import {SimpleGrid} from '@chakra-ui/react'

import {maxWidth, space} from 'config/theme'

import F1, {content as f1content} from 'content/home/feature-1.mdx'
import F2, {content as f2content} from 'content/home/feature-2.mdx'
import F3, {content as f3content} from 'content/home/feature-3.mdx'

import CardLink from './card-link'

const features = [
  {
    title: f1content.title,
    link: f1content.link,
    body: <F1 />
  },
  {
    title: f2content.title,
    link: f2content.link,
    body: <F2 />
  },
  {
    title: f3content.title,
    link: f3content.link,
    body: <F3 />
  }
]

export default function FeatureCards() {
  return (
    <SimpleGrid
      minChildWidth='28ch'
      maxWidth={maxWidth}
      spacingX={space.md}
      spacingY={16}
      width='100%'
    >
      {features.map((feature, i) => (
        <CardLink key={i} title={feature.title} link={feature.link}>
          {feature.body}
        </CardLink>
      ))}
    </SimpleGrid>
  )
}
