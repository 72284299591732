import {Box, BoxProps} from '@chakra-ui/react'
import Image from 'next/image'
import Script from 'next/script'
import {CSSProperties} from 'react'

import BrowserBar from './browser-bar'

const swatchStyle: CSSProperties = {
  height: '100%',
  left: 0,
  opacity: 0,
  overflow: 'hidden',
  position: 'absolute',
  top: 0,
  transition: 'opacity 200ms',
  width: '100%'
}

const imgStyle: CSSProperties = {
  filter: 'blur(5px)',
  height: '100%',
  objectFit: 'contain',
  width: '100%'
}

type WistiaVideoProps = BoxProps & {
  id: string
}

const assetsScript = 'https://fast.wistia.com/assets/external/E-v1.js'

/**
 * Display a Wistia Video
 */
export default function WistiaVideo({id, ...p}: WistiaVideoProps) {
  const embedScript = `https://fast.wistia.com/embed/medias/${id}.jsonp`

  return (
    <Box bg='white' roundedTop='md' {...p}>
      <Script src={assetsScript} strategy='lazyOnload' />
      <Script src={embedScript} strategy='lazyOnload' />

      <BrowserBar />
      <div
        className='wistia_responsive_padding'
        style={{padding: '53.44% 0 0 0', position: 'relative'}}
      >
        <div
          className='wistia_responsive_wrapper'
          style={{
            height: '100%',
            left: 0,
            position: 'absolute',
            top: 0,
            width: '100%'
          }}
        >
          <div
            className={`wistia_embed wistia_async_${id} videoFoam=true qualityMin=720`}
            style={{height: '100%', position: 'relative', width: '100%'}}
          >
            <div className='wistia_swatch' style={swatchStyle}>
              <Image
                alt='Video still'
                layout='fill'
                objectFit='contain'
                src={`https://fast.wistia.com/embed/medias/${id}/swatch`}
              />
            </div>
          </div>
        </div>
      </div>
    </Box>
  )
}
