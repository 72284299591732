
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const content = {
  "heroImg": "/img/hero.jpeg",
  "clientsOriginal": "Conveyal has collaborated with these organizations around the globe",
  "clientsTitle": "Meet some of our clients",
  "clientsSubtitle": "Making forward-thinking cities around the globe faster, smarter, and more efficient.",
  "wisitaVideoId": "ts8wjmijb7"
};

const layoutProps = {
  content
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;