import {Box, SimpleGrid, SimpleGridProps} from '@chakra-ui/react'
import Image from 'next/image'
import Link from 'next/link'

import AllClients from 'config/clients'

type ClientLogoGridProps = SimpleGridProps & {
  limit?: number
}

/**
 * Display client logos.
 */
export default function ClientLogoGrid({limit, ...props}: ClientLogoGridProps) {
  return (
    <SimpleGrid
      columns={{base: 2, xl: 3}}
      spacingX={{base: 20, md: 32}}
      spacingY={{base: 16, md: 20}}
      {...props}
    >
      {AllClients.slice(0, limit).map((client) => (
        <Link key={client.id} href={`/clients#${client.id}`} passHref>
          <Box
            cursor='pointer'
            filter='grayscale(1)'
            height={{base: '40px', md: '50px', lg: '60px'}}
            position='relative'
            width={{base: '160px', md: '200px', lg: '240px'}}
          >
            <Image
              alt={`${client.name} logo`}
              quality={50}
              layout='fill'
              objectFit='contain'
              src={client.logo}
            />
          </Box>
        </Link>
      ))}
    </SimpleGrid>
  )
}
