import {Box, BoxProps, Flex} from '@chakra-ui/react'

const lightRadius = {base: '7px', md: '10px'}
const Light = (p: BoxProps) => (
  <Box height={lightRadius} width={lightRadius} borderRadius='50%' {...p} />
)

export default function BrowserBar() {
  return (
    <Box
      borderBottomWidth='1px'
      bg='gray.200'
      roundedTop='md'
      height={{base: '21px', md: '30px'}}
    >
      <Flex alignItems='center' height='100%' pl={lightRadius}>
        <Light bg='red.400' mr={2} />
        <Light bg='yellow.400' mr={2} />
        <Light bg='green.400' />
      </Flex>
    </Box>
  )
}
