import {Box, BoxProps, Heading, Stack} from '@chakra-ui/react'
import Link from 'next/link'

import {space} from 'config/theme'

type CardLinkProps = BoxProps & {
  link: Conveyal.Link
}

export default function CardLink({
  children,
  title,
  link,
  ...props
}: CardLinkProps) {
  return (
    <Link href={link.href} passHref>
      <Stack
        backgroundColor='white'
        borderWidth='1px'
        boxShadow='lg'
        cursor='pointer'
        justifyContent='space-between'
        p={space.md}
        rounded='md'
        spacing={space.md}
        _hover={{
          boxShadow: '2xl'
        }}
        {...props}
      >
        <Stack spacing={space.md}>
          <Heading color='blue.800' size='lg' width='100%'>
            {title}
          </Heading>
          <Box>{children}</Box>
        </Stack>

        <Box color='blue.600'>{link.title} →</Box>
      </Stack>
    </Link>
  )
}
