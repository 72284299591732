
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */

export const content = {
  "title": "Rapidly compare impacts",
  "link": {
    "title": "Learn more",
    "href": "/learn#speed"
  }
};

const layoutProps = {
  content
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Your team can evaluate scenarios in terms of access to opportunities, accounting for uncertainty and variation in travel experience. Our cutting-edge methods and cloud computation handle millions of origins and destinations in seconds.`}</p>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;