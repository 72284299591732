import {Box, Center, Heading} from '@chakra-ui/react'
import Image from 'next/image'

import {space} from 'config/theme'
import heroImg from 'public/img/hero.jpeg'

export type HeroImageProps = {
  title: JSX.Element
}

const imgHeight = 750
const height = {base: imgHeight / 2, md: imgHeight}

const bgColor = 'rgba(35, 137, 201, 0.25)'
const bg600 = 'rgba(21, 95, 153, 0.35)' // 600
const bg700 = 'rgba(9, 61, 111, 0.35)' // 700
const bg800 = 'rgba(0, 31, 69, 0.25)' // 800
const darkerBg = 'rgba(0, 9, 29, 0.25)' // 900

export default function HeroImage({title}: HeroImageProps) {
  return (
    <Box overflow='hidden' position='relative' height={height} width='100%'>
      <Box
        position='absolute'
        left='0'
        right='0'
        marginX='auto'
        height={height}
        width='100%'
      >
        <Image
          alt='Hero image'
          layout='fill'
          objectFit='cover'
          placeholder='blur'
          src={heroImg}
        />
      </Box>
      <Box
        position='absolute'
        top='0px'
        bottom='0px'
        right='0px'
        left='0px'
        backgroundColor={bg700}
      />
      <Center height='100%' px={space.lg}>
        <Heading
          color='white'
          fontSize={{base: '2.75rem', md: '5.5rem'}}
          lineHeight={{base: '2.5rem', md: '5rem'}}
          position='relative'
          textAlign='center'
          textShadow='2px 2px 10px rgba(0,0,0, 0.5)'
          zIndex={1}
        >
          {title}
        </Heading>
      </Center>
    </Box>
  )
}
